'use client';
import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
import TextfieldWrapper from '@/components/formComponents/Textfield';
import { otherPropsRequired } from '@/components/formComponents/requiredStyleJson/otherProps';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
import { setAlertPopup } from '@/store/alertSlice';
import { setLoginDetails } from '@/store/authSlice';
import { callApi } from '@/utils/apirequest';
import {
  nameValidation,
  passwordValidation,
} from '@/utils/validations/CommonValidations';
import IMAGES from '@/utils/validations/ImagePaths';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const LoginPage = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { setLoading } = useContext(LoadingContext);
  const theme = useTheme();

  const INITIAL_FORM_STATE = {
    username: '',
    password: '',
    // rememberme: true,
  };

  const FORM_VALIDATION = Yup.object().shape({
    username: nameValidation('Username', true),
    password: passwordValidation('Password', true),
    //rememberme: Yup.boolean().oneOf([true], ''),
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    console.log(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const Login = async values => {
    setLoading(true);

    const data = { ...values };

    try {
      const response = await callApi('adminLogin', data);
      setLoading(false);
      console.log(response);

      if (response.data.result) {
        dispatch(setLoginDetails(response.data.result));
        router.push(`/dashboard`);
      } else {
        console.error('Failed to fetch:', response.data.message);
        dispatch(
          setAlertPopup({
            message: response.data.message,
            type: 'error',
            duration: 3000,
          })
        );
      }
    } catch (err) {
      dispatch(
        setAlertPopup({
          message: 'Something went wrong Please try again!',
          type: 'error',
          duration: 3000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '3px solid red',
      }}
    >
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          console.log(values);

          Login(values, { resetForm });
        }}
      >
        {({
          // errors,
          // handleBlur,
          // handleChange,
          handleSubmit,
          // setFieldValue,
          // isSubmitting,
          // touched,
          // values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                maxWidth: 1000,
                margin: 'auto',

                // mt: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <Image src={IMAGES?.NN} alt="nn" height={100} width={100} />
              </Box>
              <CardContent>
                <Card
                  sx={{
                    maxWidth: 600,
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Card variant="outlined" sx={{ p: 5 }}>
                    <Typography
                      variant="h4"
                      textAlign="center"
                      marginBottom={4}
                    >
                      Login to Namaste Nelamangala Admin App
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          autoFocus
                          autoComplete="off"
                          name="username"
                          label="Username"
                          otherProps={otherPropsRequired}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          autoComplete="off"
                          name="password"
                          label="Password"
                          otherProps={otherPropsRequired}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Box sx={{ py: 2 }}>
                        <Grid item xs={12} container justifyContent="center">
                          <ShadowButtonSubmit
                            height="50px"
                            width="100%"
                            minwidth="250px"
                            maxwidth="275px"
                            backgroundcolor={theme.palette.primary.main}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <SubmitButtonText color="#fff">
                              Login
                            </SubmitButtonText>
                          </ShadowButtonSubmit>
                        </Grid>
                      </Box>
                    </Grid>
                  </Card>
                </Card>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginPage;
